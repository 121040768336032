<template>
  <v-dialog :value="value"
            :max-width="options.width"
            :color="options.color"
            :persistent="persistent"
            @keydown.esc="$emit('cancel', $event)"
            @click:outside="!persistent ? $emit('cancel', $event) : ''"
            @input="$emit('input', $event)"
  >
    <v-card :loading="loading">
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>
        <slot name="body">
          {{ body }}
        </slot>
      </v-card-text>

      <v-card-actions v-if="hasAction">
        <v-spacer/>
        <v-btn ref="confirmDialogLeftButton"
               @click.native="$emit('cancel')"
               text
        >{{ leftButton }}
        </v-btn>
        <v-btn ref="confirmDialogRightButton"
               @click.native="$emit('valid')"
               color="primary"
               text
        >{{ rightButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',

  props: {
    body: {
      required: false,
      type: String,
      default: '',
    },
    hasAction: {
      required: false,
      type: Boolean,
      default: true,
    },
    leftButtonText: {
      required: false,
      type: String,
      default: '',
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({
        width: 500,
        color: 'primary',
      }),
    },
    persistent: {
      required: false,
      type: Boolean,
      default: false,
    },
    rightButtonText: {
      required: false,
      type: String,
      default: '',
    },
    title: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    leftButton () {
      return this.leftButtonText === '' ? this.$t('general.buttons.cancel') : this.leftButtonText
    },
    rightButton () {
      return this.rightButtonText === '' ? this.$t('general.buttons.validate') : this.rightButtonText
    },
  },
}
</script>
